<template>
  <div class="warp">
    <div>
      <h3>支持NFC的机型</h3>
      <p class="tip">将标注区域靠近刷卡区，信号最佳</p>
      <Loading v-if="!deviceList.length" height="50"/>
      <ul v-else class="model-list">
        <li v-for="(device, index) in deviceList" :key="index">
          <div class="phone">
            <img :src="device.deviceUrl" alt>
            <p>{{device.deviceName}}</p>
          </div>
          <div class="aerial">
            <img :src="device.nfcUrl" alt>
            <p>NFC天线位置</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "@/components/Loading.vue";
export default {
  name: "Models",
  data() {
    return {
      deviceList: []
    };
  },
  components: {
    Loading
  },
  async mounted() {
    const deviceList = await axios.get(
      `https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/deviceList.json?num=${Math.random()}`
    );
    this.deviceList = deviceList.data;
  }
};
</script>

<style scoped>
.warp h3 {
  text-align: center;
  margin: 5vw 0 0.5vw;
  font-size: 2vw;
  font-weight: 400;
}
.tip {
  text-align: center;
  font-size: 0.9vw;
  color: #666;
}
.warp ul.model-list {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 5vw auto;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.warp ul.model-list li {
  display: flex;
  width: 50%;
  height: 20vw;
  align-items: center;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.warp ul.model-list li div{
  width: 50%;
  text-align: center;
}
.warp ul.model-list li img {
  height: 10vw;
}
.warp ul.model-list li p {
  color: #333;
  line-height: 3vw;
  font-size: 1vw;
  text-align: center;
}
</style>